
import i18n from '@/core/plugins/i18n';
import { useI18n } from 'vue-i18n';

``;
import { defineComponent, toRefs, ref, inject, watchEffect } from 'vue';

export default defineComponent({
    props: {
        value: { type: Number || String, default: '' },
        name: { type: String, default: '' },
        display: { type: String, default: '' },
        options: Object,
        size: { type: String, default: 'medium' },
        required: { type: Boolean, default: false },
        target: { type: String, default: '' },
        rule: { type: String, default: '' },
        ruleMsg: { type: String, default: 'Invalid data' },
        requiredMsg: { type: String, default: 'This field is required' },
        callEmit: { type: Boolean, default: false }
    },

    setup(props, context) {
        const { required, rule, ruleMsg, requiredMsg, target, value, callEmit } = toRefs(props);
        const inputValue = ref(value.value);
        const i18n = useI18n();

        watchEffect(() => {
            inputValue.value = value.value;
        });

        let rules: any = ref({});
        const emitter: any = inject('emitter');

        if (required.value == true) {
            let ruleValues = {};
            if (rule.value != '') {
                ruleValues = {
                    type: rule.value,
                    message: ruleMsg.value,
                    trigger: ['change']
                };
            }

            rules = [
                {
                    required: true,
                    message: requiredMsg.value,
                    trigger: ['change']
                },
                ruleValues
            ];
        }

        const updateValue = () => {
            context.emit(`update:${target.value}`, inputValue.value);
            if (callEmit.value == true) {
                context.emit('change', value);
            }
        };

        const translate = option => {
            if (option.includes('message.')) {
                const splitKeys = option.split(' ');
                splitKeys.forEach((item, index, array) => {
                    array[index] = i18n.t(item);
                });
                return splitKeys.join(' ');
            }
            return option;
        };

        emitter.on('clearInput', () => {
            //inputValue.value = 0;
        });

        return {
            updateValue,
            inputValue,
            rules,
            translate
        };
    }
});
