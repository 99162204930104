
import { defineComponent, toRefs, ref, inject, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    props: {
        name: { type: String, default: '' },
        label: { type: String || Boolean },
        placeholder: { type: String, default: 'message.ENTER message.YOUR message.VALUE' },
        required: { type: Boolean, default: false },
        target: { type: String, default: '' },
        requiredMsg: { type: String, default: 'message.THIS_FIELD message.IS_REQUIRED' },
        value: { type: String || Number, default: '' },
        selectData: Object,
        callEmit: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        filterable: { type: Boolean, default: false },
        remote: { type: Boolean, default: false },
        reserveKeyword: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        allowCreateNew: { type: Boolean, default: false },
        focus: { type: Boolean, default: false },
        multipleLimit: { type: Number, default: 0 }
    },

    setup(props, context) {
        const { target, callEmit, value, focus } = toRefs(props);

        const selectedValue = ref('');
        const i18n = useI18n();
        const emitter: any = inject('emitter');

        const translate = (msg: string) => {
            if (msg.includes('message.')) {
                const splitKeys = msg.split(' ');
                splitKeys.forEach((item, index, array) => {
                    array[index] = i18n.t(item);
                });
                return splitKeys.join(' ');
            }
            return msg;
        };

        watchEffect(() => {
            selectedValue.value = value.value;
        });

        const updateValue = value => {
            context.emit(`update:${target.value}`, value);
            if (callEmit.value == true) {
                context.emit('change', value);
            }
        };

        const focusHandler = () => {
            if (focus.value) {
                context.emit('focus');
            }
        };

        emitter.on('clearInput', () => {
            // selectedValue.value = "";
        });

        return {
            updateValue,
            selectedValue,
            translate,
            focusHandler
        };
    }
});
